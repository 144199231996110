import React from "react"

const FeatureItem = ({ title, description }) => {
    return (
        <div className="feature__item">
            <h3 className="section__title">{title}</h3>
            <p>{description}</p>
        </div>
    )
}

export default FeatureItem
