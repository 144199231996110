import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Demo from '../components/demo'
import Wrapper from '../components/wrapper'
import Features from '../components/features';
import FeatureItem from '../components/feature-item'
import Callout from '../components/callout'

const IndexPage = () => (
  <Layout title="First class integrations for Canva, Slack, Microsoft and more">
    <SEO title="First class integrations for Canva, Slack, Microsoft and more" description="We develop first class integrations for Canva, Slack, Microsoft and more." />
    <Hero title="ThreadHeap" description="We develop first class integrations for Canva, Slack, Microsoft and more." />
    <Demo />
    <Callout title="Try Code to Image for Canva" description="Embed beautiful images of your code into Canva design and presentation" />
    <Wrapper>
      <Features>
        <FeatureItem title="Data privacy" description="We prioritize customer data privacy with GDPR-compliant data collection and processing" />
        <FeatureItem title="Secure Development" description="We employ end to end vulnerability checks during our coding and development process" />
        <FeatureItem title="Infrastructure Access" description="We use multi-factor authentication and restrict access based on team’s needs" />
      </Features>
    </Wrapper>
    {/* <Callout title="Subscribe to our newsletter" description="Subscribe to our newsletter to know first about new features and blog posts" link="https://pavel207.typeform.com/to/jIICkA" /> */}
  </Layout>
);

export default IndexPage
