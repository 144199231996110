import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Installation = ({ title, description, link }) => (
    <div className="callout">
        <p>{description}</p>
        <OutboundLink href={link} className="button--primary">{title}</OutboundLink>
    </div>
)

export default Installation