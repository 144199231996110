
import React from "react"

const Hero = ({ title, description }) => {
    return (
        <div className="hero">
            <h1 className="hero__title">{title}</h1>
            <p className="hero__description">{description}</p>
        </div>
    )
}

export default Hero
