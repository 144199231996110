
import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { StaticImage } from 'gatsby-plugin-image';

const Demo = () => {
    return (
        <div className="hero__demo">
            <OutboundLink href="" target="blank" alt="Embed beautiful code previews into your Canva designs and presentations with Code to Image extension">
                <StaticImage className="hero__demo__image" src="../images/code_to_image.png" alt="Code to image for Canva" />
            </OutboundLink>
        </div>
    )
}

export default Demo
